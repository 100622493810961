import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, forwardRef, createRef, useImperativeHandle } from "react";
import { useDispatch } from "react-redux";
import { removePersistantAlert, usePersitentAlertSelector } from "../../redux/reducers/PersistentAlertReducer/PersistentAlertReducer";
import { Alert, CloseIcon, Ref } from "@fluentui/react-northstar";
import { PersistentAlertTymeEnum } from "../../interfaces/PersistantAlert";
import { PersistantAlertCTA } from "./PersistantAlertCTA";
import { usePersistantAlertHook } from "../../hook/usePersistantAlertHook";
export const PersistentAlert = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const { persistentAlert } = usePersitentAlertSelector("persistentAlert");
    const { onAlertHeightChanged } = usePersistantAlertHook();
    const htmlRef = createRef();
    useImperativeHandle(ref, () => {
        return {
            hasAlert() {
                return persistentAlert !== null;
            },
            getAlertSize() {
                return htmlRef.current?.offsetHeight ?? 0;
            }
        };
    }, []);
    const closeAlert = () => {
        dispatch(removePersistantAlert());
    };
    useEffect(() => {
        let height = htmlRef.current?.offsetHeight;
        if (height != null)
            height += 10;
        onAlertHeightChanged(height ?? 0);
    }, [htmlRef]);
    return (_jsx(_Fragment, { children: persistentAlert && (_jsx(Ref, { innerRef: htmlRef, children: _jsx(Alert, { style: {
                    margin: 5,
                }, header: persistentAlert.title, icon: persistentAlert.icon, content: _jsx(PersistantAlertCTA, {}), visible: true, danger: persistentAlert.type === PersistentAlertTymeEnum.Error, info: persistentAlert.type === PersistentAlertTymeEnum.Info, success: persistentAlert.type === PersistentAlertTymeEnum.Success, warning: persistentAlert.type === PersistentAlertTymeEnum.Warning, actions: [
                    (persistentAlert.closable) && ({
                        icon: _jsx(CloseIcon, {}),
                        onClick: closeAlert,
                        text: true,
                        iconOnly: true,
                    })
                ] }) })) }));
});
