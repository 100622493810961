import { useDispatch } from "react-redux";
import { setPersistantAlertHeight, usePersitentAlertSelector } from "../redux/reducers/PersistentAlertReducer/PersistentAlertReducer";
export const usePersistantAlertHook = () => {
    const { persistentAlert, persistentAlertHeight } = usePersitentAlertSelector("persistentAlert", "persistentAlertHeight");
    const dispatch = useDispatch();
    const onAlertHeightChanged = (size) => {
        dispatch(setPersistantAlertHeight(size));
    };
    return {
        persistentAlert,
        onAlertHeightChanged,
        persistentAlertHeight
    };
};
