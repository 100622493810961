import { jsx as _jsx } from "react/jsx-runtime";
import { AppsIcon, ExclamationCircleIcon, HorizontalRuleIcon, QnaIcon, } from "@fluentui/react-northstar";
import { useTranslate } from "../../hook/useTranslate";
import { translations } from "../../translations";
import { generateGUID } from "../../utils/generateGUID";
import { SUGGEST_FEATURE_URL } from "../../const/const";
import { useMsTeamsSelector } from "../../redux/reducers/MicrosoftTeamsReducer/MicrosoftTeamsReducer";
import { LightbulbFilament24Regular } from "@fluentui/react-icons";
import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openModal, setModalWithData } from "../../redux/reducers/ModalReducer/ModalReducer";
import { Modal } from "../../interfaces/Modal";
import { useNewsCache } from "../../hook/useNewsCache";
import { useAppConfigurationSelector } from "../../redux/reducers/AppConfigurationReducer/AppConfigurationReducer";
import { convertFromToAppType, getHelpCenterUrl } from "./InformationMenu.utils";
import { useConfigurationSelector } from "../../redux/reducers/ConfigurationReducer/ConfigurationReducer";
import { IframeManager } from "../../utils/IframeManager";
import { getMoreAppsUrl } from "../../apis/AdminCenter/adminCenterApi";
import { configIframe } from "../../const/configIframe";
export const useInformationMenu = (props) => {
    const t = useTranslate(translations);
    const dispatchCtx = useDispatch();
    const { locale } = useMsTeamsSelector("locale");
    const { from } = useAppConfigurationSelector("from");
    const { queryNews } = useNewsCache({ appType: convertFromToAppType(from) });
    const { isOnMobile } = useMsTeamsSelector("isOnMobile");
    const { data: config } = useConfigurationSelector("data");
    const appInsightInstance = useSelector((s) => s.infosApp.appInsightInstance);
    const openAboutDialog = useCallback(() => dispatchCtx(openModal(Modal.ABOUT)), []);
    const openWhatsNews = useCallback(() => dispatchCtx(setModalWithData({ isOpen: Modal.NEWS, data: { skipLocalStorage: true } })), []);
    const linkRedirection = (url) => window.open(url, "_blank");
    const dataFunction = (data) => {
        if (data.err && !data.result) {
            !!appInsightInstance &&
                appInsightInstance?.trackEvent({
                    name: configIframe.Catalog.trackingIds.cancel,
                });
        }
        else {
            !!appInsightInstance && appInsightInstance?.trackEvent({ name: configIframe.Catalog.trackingIds.validate });
        }
    };
    const defaultMenu = useMemo(() => {
        const options = [
            { key: generateGUID(), icon: _jsx(ExclamationCircleIcon, { outline: true, "data-testid": "navbar-info-btn-about" }), content: t("About"), onClick: openAboutDialog },
        ];
        if (from !== "SharedCalendar") {
            options.push({
                key: generateGUID(),
                icon: _jsx(QnaIcon, { outline: true, "data-testid": "navbar-info-btn-help" }),
                content: t("HelpCenter"),
                onClick: () => linkRedirection(getHelpCenterUrl(from)),
            }, {
                key: generateGUID(),
                icon: _jsx(LightbulbFilament24Regular, { "data-testid": "navbar-info-btn-suggest" }),
                content: t("SuggestFeature"),
                onClick: () => linkRedirection(SUGGEST_FEATURE_URL),
            }, {
                key: generateGUID(),
                icon: _jsx(HorizontalRuleIcon, { outline: true, "data-testid": "navbar-info-btn-whats-new" }),
                content: t("WhatsNewsOn"),
                onClick: openWhatsNews,
                disabled: !queryNews.data?.length,
            }, {
                key: generateGUID(),
                icon: _jsx(AppsIcon, { outline: true, "data-testid": "navbar-info-btn-apps" }),
                content: t("MoreApps"),
                onClick: () => IframeManager(configIframe.Catalog, false, appInsightInstance, from, getMoreAppsUrl(config.adminCenterAppId, config.apiSubscription), getMoreAppsUrl(config.adminCenterAppId, null, config.adminCenterBaseUrl), dataFunction),
            });
        }
        return options;
    }, [t, openAboutDialog, openWhatsNews, linkRedirection, from, queryNews.data?.length]);
    const [menu, setMenu] = useState([]);
    useEffect(() => {
        // if (queryNews.status !== "success" && queryNews.status !== "error") return;
        if (props.customItems) {
            setMenu([...defaultMenu, ...props.customItems]);
        }
        else {
            setMenu([...defaultMenu]);
        }
    }, [props.customItems, from, queryNews.status]);
    return { t, menu };
};
