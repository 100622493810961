import { useState } from "react";
/**
 * Popup shown when clicking on a calendar event
 */
export const usePopupLogic = () => {
    const [state, setState] = useState({ open: false });
    /**
     * Get first part of email
     * @param email email
     */
    const getEmailFirstPart = (email) => email.substring(0, email.indexOf("@"));
    /**
     * On open change
     * @param event
     * @param data
     */
    const onOpenChange = (event, data) => setState({ open: data?.open ?? false });
    /**
     * On close popup
     */
    const onClose = () => setState({ open: false });
    return {
        state,
        getEmailFirstPart,
        onOpenChange,
        onClose,
    };
};
