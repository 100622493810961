import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { AddIcon, Button, CloseIcon, FilesPdfColoredIcon, FilesPictureColoredIcon, FilesTextColoredIcon, Flex, Text, Tooltip } from "@fluentui/react-northstar";
import { openStageView, useModalSelector, useMsTeamsSelector, usePremiumSelector, useTranslate } from "front";
import { labelsMaxWidth } from "../event/Form/EventForm.logic";
import { translations } from "../../translations";
import { useFilesLogic } from "./FilesComponent.logic";
export const FilesComponent = (props) => {
    const { isOnMobile, isLightTheme } = useMsTeamsSelector("isOnMobile", "isLightTheme");
    const { isPremium } = usePremiumSelector("isPremium");
    const { isOpen } = useModalSelector("isOpen");
    const logic = useFilesLogic(props);
    const t = useTranslate(translations);
    const renderFileByType = (f) => {
        let render;
        if (f.extensions.includes("image"))
            render = _jsx(FilesPictureColoredIcon, { size: "large" });
        else if (f.extensions.includes("pdf"))
            render = _jsx(FilesPdfColoredIcon, { size: "large" });
        else
            render = _jsx(FilesTextColoredIcon, { size: "large" });
        return (_jsx(Tooltip, { content: f.name, children: _jsx(Flex, { column: true, className: "file-bg", styles: { cursor: "pointer", padding: "5px", borderRadius: "3px" }, onClick: () => openStageView(f.url), children: _jsxs(Flex, { children: [render, _jsx(Text, { className: "text-no-wrap", styles: { paddingLeft: "5px", paddingRight: "5px", width: "70px", overflow: "hidden", textOverflow: "ellipsis", height: "20px" }, children: f.name }), isPremium && _jsx(CloseIcon, { size: "medium", styles: { paddingTop: "2px" }, disabled: props.disabled, onClick: (e) => logic.deleteFile(e, f.name) })] }) }) }));
    };
    return (_jsx(_Fragment, { children: _jsxs(Flex, { column: isOnMobile, gap: isOnMobile ? "gap.small" : "gap.medium", "data-testid": "event-view-form-files-field", children: [_jsx(Flex.Item, { styles: { minWidth: labelsMaxWidth }, children: _jsxs(Flex, { vAlign: "center", gap: "gap.small", children: [_jsx("svg", { className: isLightTheme ? "" : "filter-file-icon", xmlns: "http://www.w3.org/2000/svg", width: "20", height: "20", viewBox: "0 0 24 24", children: _jsx("path", { fill: "#000000", d: "M18.5 20a.5.5 0 0 1-.5.5h-5.732A6.518 6.518 0 0 1 11.19 22H18a2 2 0 0 0 2-2V9.828a2 2 0 0 0-.586-1.414l-5.829-5.828a.491.491 0 0 0-.049-.04a.63.63 0 0 1-.036-.03a2.072 2.072 0 0 0-.219-.18a.652.652 0 0 0-.08-.044l-.048-.024l-.05-.029c-.054-.031-.109-.063-.166-.087a1.977 1.977 0 0 0-.624-.138a.56.56 0 0 1-.059-.007a.605.605 0 0 0-.082-.007H6a2 2 0 0 0-2 2v7.498a6.451 6.451 0 0 1 1.5-.422V4a.5.5 0 0 1 .5-.5h6V8a2 2 0 0 0 2 2h4.5v10Zm-5-15.379L17.378 8.5H14a.5.5 0 0 1-.5-.5V4.621ZM12 17.5a5.5 5.5 0 1 0-11 0a5.5 5.5 0 0 0 11 0ZM7 18l.001 2.503a.5.5 0 1 1-1 0V18H3.496a.5.5 0 0 1 0-1H6v-2.5a.5.5 0 1 1 1 0V17h2.497a.5.5 0 0 1 0 1H7Z" }) }), _jsx(Text, { truncated: true, content: logic.getContentFileMemo })] }) }), _jsx(Flex.Item, { grow: true, children: _jsx(Flex, { column: true, style: { width: "80%" }, children: _jsxs(Flex, { children: [_jsx(Flex, { styles: { maxWidth: "calc(100% - 100px)", overflowX: "auto" }, children: props.event?.files?.map((f, i) => _jsx("div", { style: { paddingRight: "5px" }, children: renderFileByType(f) }, i)) }), _jsx(Button, { style: { minWidth: "auto" }, primary: true, icon: _jsx(AddIcon, { outline: true }), disabled: !isPremium || props.disabled, content: t("Add"), onClick: logic.addFiles, "data-testid": "event-view-form-files-field-btn" }), _jsx("input", { type: "file", id: "file-selector-input", onChange: logic.updateFile, style: { visibility: "hidden", position: "absolute" } })] }) }) })] }) }));
};
