import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useState, useRef } from "react";
import ReactQuill from "react-quill";
import * as showdown from "showdown";
import { Flex } from "@fluentui/react-northstar";
import "react-quill/dist/quill.snow.css";
import "./richTextEditor.scss";
import { translations } from "../../translations";
import { useTranslate } from "../../hook/useTranslate";
const modules = {
    toolbar: [["bold", "italic"], ["link"]],
};
export const RichTextEditor = (props) => {
    const [text, setText] = useState("");
    const [textLength, setTextLength] = useState(0);
    const oldContent = useRef("");
    const editorRef = useRef(null);
    const parser = new showdown.Converter();
    let handleChangeTimeout = useRef(null);
    const t = useTranslate(translations);
    useEffect(() => {
        const tooltipInput = document.querySelector(".ql-tooltip input[data-link]");
        tooltipInput.placeholder = "https://www.teams-pro.com/";
        tooltipInput.dataset.link = "https://www.teams-pro.com/";
        const tooltip = document.querySelector(".ql-tooltip");
        const label = document.createElement("p");
        label.innerText = `${t("EditTextLink")}:`;
        tooltip.prepend(label);
        const styles = `
        .ql-tooltip[data-mode=link] a.ql-action::after {
            content: "${t("EditTextConfirm")}" !important;
        }

        .ql-tooltip a.ql-action::after {
            content: "${t("EditTextEdit")}" !important;
        }

        .ql-tooltip a.ql-remove::before {
            content: "${t("EditTextRemove")}" !important;
        }
    `;
        const styleSheet = document.createElement("style");
        styleSheet.innerText = styles;
        document.head.appendChild(styleSheet);
    }, []);
    useEffect(() => {
        if (!!props.setValue && !oldContent.current) {
            setText(props.setValue);
        }
        if (text !== oldContent.current) {
            if (editorRef.current) {
                const textLength = editorRef.current?.getEditor().getLength() - 1;
                setTextLength(textLength);
            }
        }
    }, [props.setValue, text]);
    const setTextContent = (content) => {
        if (textLength <= props.maxContent) {
            setText(content);
            if (handleChangeTimeout.current)
                clearTimeout(handleChangeTimeout.current);
            handleChangeTimeout.current = setTimeout(() => {
                props.handleChange(parser.makeHtml(content));
            }, 300);
        }
    };
    const onChange = (content) => {
        if (editorRef.current) {
            const textLength = editorRef.current?.getEditor().getLength() - 1;
            if (!!props.maxContent && textLength <= props.maxContent) {
                let pattern = RegExp('href="(.*?)"', "g");
                let groups;
                while ((groups = pattern.exec(content)) !== null) {
                    if (!groups[1].match("https?://")) {
                        content = content.replace(`${groups[1]}`, `https://${groups[1]}`);
                    }
                }
                setTextContent(content);
                oldContent.current = editorRef.current?.getEditor().getText();
            }
            else {
                editorRef.current?.getEditor().setText(oldContent.current);
            }
        }
    };
    return (_jsx(Flex, { fill: true, "data-testid": "rich-text-editor", children: _jsx(Flex, { className: "richTextContainer", style: { width: "100%", height: props.maxHeight + "px" }, children: _jsx(ReactQuill, { ref: editorRef, theme: "snow", value: text, onChange: onChange, defaultValue: "add description here...", modules: modules, className: "richTextEditor", readOnly: props.readOnly }) }) }));
};
