import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { Button, ClipboardCopiedToIcon, Dialog, Flex, Popup, Text } from '@fluentui/react-northstar';
import copy from 'copy-to-clipboard';
import { translations } from '../../../translations';
import { useTranslate } from 'front';
import moment from 'moment';
const EventAboutDialog = (props) => {
    const t = useTranslate(translations);
    const [showPopup, setShowPopup] = useState(false);
    const [items, setItems] = useState([]);
    useEffect(() => {
        let items = [];
        items.push({
            key: 'idevent',
            header: t('InfoEventId'),
            content: props.event?.id ?? "",
        });
        if (!!props.event.groupId && props.event.id !== props.event.groupId) {
            items.push({
                key: 'ideventserie',
                header: t('InfoEventSerieId'),
                content: props.event.groupId,
            });
        }
        items.push({
            key: 'createdby',
            header: props.event.readOnly ? t('BelongsTo') : t('InfoEventCreatedBy'),
            content: `${props.event?.createdBy ?? ""}\n${moment(props.event?.created).format('YYYY/MM/DD - HH:mm') === "0001/01/01 - 00:00" ? "" : moment(props.event?.created).format('YYYY/MM/DD - HH:mm')}`,
        });
        if (props.event.updatedBy != null) {
            items.push({
                key: 'updatedby',
                header: t('InfoEventUpdatedBy'),
                content: `${props.event.updatedBy ?? ''}\n${moment(props.event?.updated).format('YYYY/MM/DD - HH:mm') === "0001/01/01 - 00:00" ? "" : moment(props.event?.updated).format('YYYY/MM/DD - HH:mm')}`,
            });
        }
        if (!!props.event.timeZone) {
            items.push({
                key: 'timezone',
                header: t('InfoEventTimezone'),
                content: props.event.timeZone ?? '',
            });
        }
        setItems(items);
    }, [props.event]);
    const copyToClipboard = () => {
        setShowPopup(true);
        setTimeout(() => {
            setShowPopup(false);
        }, 1000);
        const itemsToCopy = items.find((i) => i.key === "idevent");
        copy(itemsToCopy.content);
    };
    return (_jsx(Dialog, { closeOnOutsideClick: false, className: 'InfoEventModal', open: props.show, header: t('infoEventHeader'), confirmButton: t('Close'), onConfirm: props.onHide, content: _jsx(Flex, { gap: "gap.small", space: "between", children: _jsx(Flex, { column: true, gap: "gap.medium", children: items.map((item) => (_jsxs(Flex, { column: true, children: [_jsx(Text, { content: item.header, weight: "bold" }), _jsxs(Flex, { vAlign: "center", gap: 'gap.small', children: [_jsx(Text, { style: { whiteSpace: 'pre-wrap' }, content: item.content }), item.key === "idevent" ?
                                    _jsx(Popup, { open: showPopup, content: t('Copied'), trigger: _jsx(Button, { title: t('CopyToClipboard'), iconOnly: true, icon: _jsx(ClipboardCopiedToIcon, {}), text: true, onClick: copyToClipboard }) }) : null] })] }, item.key))) }) }), "data-testid": "event-view-about-modal" }));
};
export default EventAboutDialog;
