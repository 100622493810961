import { jsx as _jsx } from "react/jsx-runtime";
import { DisplayMode, MoreOptionsAction } from "../../../interfaces/Enums";
import { useEffect, useMemo, useState } from "react";
import { useCalendarCache } from "../../../services/cacheService/calendarCache";
import { IAppType, ThemeClass, openModal, openPremiumDialog, useMsTeamsSelector, useNewsCache, usePremiumSelector, useTranslate, } from "front";
import { Print28Regular } from "@fluentui/react-icons";
import { translations } from "../../../translations";
import { ArrowSyncIcon, SettingsIcon, ShieldKeyholeIcon, TagIcon, GridIcon, BellIcon, CalendarIcon, } from "@fluentui/react-northstar";
import { setNavbar, useNavBarSelector } from "../../../redux/reducers/navbarReducer";
import { useDispatch, useSelector } from "react-redux";
import { convertHtmlElementToPng } from "../Calendar.utils";
import momentTz from "moment-timezone";
import { useNavigate } from "react-router-dom";
import { Modal } from "../../../interfaces/modalInterface";
import { useOptionsSelector } from "../../../redux/reducers/optionsReducer";
import { CrownIcon } from "../../../icons/Crown";
export const localItemName = (id) => {
    return `not-selected-calendar-${id}`;
};
export const timeZones = momentTz.tz.names();
export const useLogic = (props) => {
    const t = useTranslate(translations);
    const { allowPerms, allowSync, nameApp, allowNotification, allowMeetingScheduler, allowWhatNew } = useOptionsSelector("allowSync", "allowPerms", "nameApp", "allowNotification", "allowMeetingScheduler", "allowWhatNew");
    const { calendar: calendarCache } = useCalendarCache();
    const { queryNews } = useNewsCache({ appType: IAppType.CALENDAR });
    const [searchQuery, setSearchQuery] = useState("");
    const [searchTagsQuery, setSearchTagsQuery] = useState("");
    const [tagsQuery, setTagsQuery] = useState("");
    const [moreOptionsDropdownItems, setMoreOptionsDropdownItems] = useState([
        {
            key: MoreOptionsAction.Settings,
            content: t("Settings"),
            icon: _jsx(SettingsIcon, { outline: true, "data-testid": "navbar-more-options-btn-settings" }),
            value: MoreOptionsAction.Settings,
        },
        {
            key: MoreOptionsAction.Permissions,
            content: t("Permissions"),
            icon: _jsx(ShieldKeyholeIcon, { outline: true, "data-testid": "navbar-more-options-btn-perms" }),
            value: MoreOptionsAction.Permissions,
        },
        {
            key: MoreOptionsAction.Tags,
            content: t("Tag:Title"),
            icon: _jsx(TagIcon, { outline: true, "data-testid": "navbar-more-options-btn-tags" }),
            value: MoreOptionsAction.Tags,
        },
        {
            key: MoreOptionsAction.Sync,
            content: t("SynchronizeAgenda"),
            icon: _jsx(ArrowSyncIcon, { outline: true, "data-testid": "navbar-more-options-btn-sync" }),
            value: MoreOptionsAction.Sync,
        },
    ]);
    const displayModesDropdownItems = [
        {
            content: t("Month"),
            key: DisplayMode.Month,
            value: DisplayMode.Month,
        },
        {
            content: t("Week"),
            key: DisplayMode.Week,
            value: DisplayMode.Week,
        },
        {
            content: t("Day"),
            key: DisplayMode.Day,
            value: DisplayMode.Day,
        },
    ];
    const { displayMode, searchTag } = useNavBarSelector("displayMode", "searchTag");
    const { isPremium, isPlatinum, loaded } = usePremiumSelector("isPremium", "isPlatinum", "loaded");
    const { calendar } = useCalendarCache();
    const { isOnMobile, userId, themeClass } = useMsTeamsSelector("isOnMobile", "userId", "themeClass");
    const appInsightInstance = useSelector((s) => s.infosApp.appInsightInstance);
    const [state, setState] = useState({
        showGetIdDialog: false,
        imageToPrint: "",
    });
    const [isDropdownSharedCalendarsOpen, setIsDropdownSharedCalendarsOpen] = useState(false);
    const [isDropdownTagsOpen, setIsDropdownTagsOpen] = useState(false);
    const dispatch = useDispatch();
    const navigation = useNavigate();
    useEffect(() => {
        let tmpMoreOptionsDropdownItems = [...moreOptionsDropdownItems];
        if (allowMeetingScheduler && isPremium && !moreOptionsDropdownItems.some((m) => m.key == MoreOptionsAction.MeetingScheduler)) {
            tmpMoreOptionsDropdownItems.push({
                key: MoreOptionsAction.MeetingScheduler,
                content: t("MeetingScheduler"),
                icon: _jsx(CalendarIcon, { outline: true, "data-testid": "navbar-more-options-btn-meeting-scheduler" }),
                value: MoreOptionsAction.MeetingScheduler,
            });
        }
        if (allowNotification && !moreOptionsDropdownItems.some((m) => m.key == MoreOptionsAction.Notifications)) {
            tmpMoreOptionsDropdownItems.push({
                key: MoreOptionsAction.Notifications,
                content: t("Notifications"),
                icon: _jsx(BellIcon, { outline: true, "data-testid": "navbar-more-options-btn-notifs" }),
                value: MoreOptionsAction.Notifications,
            });
        }
        if (!isOnMobile && !moreOptionsDropdownItems.some((m) => m.key == MoreOptionsAction.Print)) {
            tmpMoreOptionsDropdownItems.push({ key: MoreOptionsAction.Print, content: t("Print"), icon: _jsx(Print28Regular, { "data-testid": "navbar-more-options-btn-print" }), value: MoreOptionsAction.Print });
        }
        if ((nameApp.toLocaleLowerCase().includes("parking") || nameApp.toLocaleLowerCase().includes("booking")) && !tmpMoreOptionsDropdownItems.some((t) => t.key == MoreOptionsAction.Rooms)) {
            tmpMoreOptionsDropdownItems.push({
                key: MoreOptionsAction.Rooms,
                content: t("RoomsManagement"),
                icon: _jsx(GridIcon, { outline: true, "data-testid": "navbar-more-options-btn-rooms" }),
                value: MoreOptionsAction.Rooms,
            });
        }
        if (!allowPerms) {
            tmpMoreOptionsDropdownItems = tmpMoreOptionsDropdownItems.filter((t) => t.key != MoreOptionsAction.Permissions);
        }
        if (!allowSync) {
            tmpMoreOptionsDropdownItems = tmpMoreOptionsDropdownItems.filter((t) => t.key != MoreOptionsAction.Sync);
        }
        if (loaded && !isPlatinum && !moreOptionsDropdownItems.some((m) => m.key == MoreOptionsAction.Upgrade)) {
            tmpMoreOptionsDropdownItems.push({
                key: MoreOptionsAction.Upgrade,
                content: t("UpgradeToPaidPlan"),
                icon: _jsx("span", { role: "img", "aria-hidden": "true", className: "ui-icon ff bp fg", "data-testid": "navbar-more-options-btn-upgrade", children: _jsx(CrownIcon, { permissions: isPremium ? "platinium" : "premium" }) }),
                value: MoreOptionsAction.Upgrade,
            });
        }
        setMoreOptionsDropdownItems(tmpMoreOptionsDropdownItems);
    }, [isOnMobile, allowPerms, allowSync, nameApp, isPremium, isPlatinum, calendarCache, allowNotification, allowMeetingScheduler, loaded]);
    const filteredTags = useMemo(() => {
        return calendar?.tags
            ?.sort((a, b) => a.localeCompare(b))
            ?.filter((tag) => searchTagsQuery !== "" ? tag.toLowerCase().includes(searchTagsQuery.toLowerCase()) : tag);
    }, [calendar?.tags, searchTagsQuery]);
    /*useEffect(()=>{
          if(state.imageToPrint == "") return
          // @ts-ignore
          const iframe = window.frames["printContentIframe"];
          if (!!state.imageToPrint && !!iframe) {
              iframe.focus();
              iframe.print();
          }
      },[state.imageToPrint])*/
    /**
     * When user has filtered events by a specific tag
     * @param event event
     * @param data dropdown data
     */
    const onFilterEventsByTag = async (tag) => {
        let newSelectedTags = searchTag !== "" ? searchTag.split(";;;") : [];
        if (newSelectedTags.includes(tag)) {
            newSelectedTags = newSelectedTags.filter((t) => t !== tag);
        }
        else {
            newSelectedTags.push(tag);
        }
        dispatch(setNavbar({ searchTag: newSelectedTags.sort((a, b) => (a > b ? 1 : -1)).join(";;;") }));
        const filteredEvents = props.events.filter((e) => newSelectedTags.some((tag) => e.tags?.includes(tag)));
        await props.filterEvents(filteredEvents);
        setTagsQuery("");
    };
    /**
     * Manage shared calendars displayed
     */
    const onDropdownSharedCalendarsToggle = () => {
        setIsDropdownSharedCalendarsOpen(true);
    };
    const onDropdownTagsToggle = () => {
        setIsDropdownTagsOpen(true);
    };
    const handleSelectedCalendars = (displayedCalendar) => {
        const entry = new Map();
        props.setDisplayedCalendars((prev) => {
            const updatedCalendarsSelection = prev.map((c) => {
                if (c.id === displayedCalendar.id)
                    c.display = !c.display;
                entry.set(c.id, c.display);
                return c;
            });
            return updatedCalendarsSelection;
        });
        localStorage.setItem(localItemName(calendar.id), JSON.stringify(Object.fromEntries(entry)));
    };
    /**
     * Show previous events
     */
    const showPreviousEvents = async () => {
        if (!props.calendarRef?.current)
            return;
        let calendarApi = props.calendarRef.current.getApi();
        calendarApi.prev();
        dispatch(setNavbar({ activeStartDate: calendarApi?.currentDataManager?.getCurrentData().viewApi.currentStart }));
        //await props.refreshEvents();
    };
    /**
     * Show next events
     */
    const showNextEvents = async () => {
        if (!props.calendarRef?.current)
            return;
        let calendarApi = props.calendarRef.current.getApi();
        calendarApi.next();
        const activeStartDate = calendarApi?.currentDataManager?.getCurrentData().viewApi.currentStart;
        dispatch(setNavbar({ activeStartDate }));
    };
    /**
     * Disable today button if current events are from today
     */
    const isTodayButtonDisabled = () => {
        if (props.calendarRef.current) {
            let calendarApi = props.calendarRef.current.getApi();
            let today = new Date();
            if ((calendarApi.view.activeStart >= today && today <= calendarApi.view.activeEnd) || (calendarApi.view.activeStart <= today && today >= calendarApi.view.activeEnd))
                return false;
        }
        return true;
    };
    /**
     * Show today events
     */
    const showTodayEvents = async () => {
        if (!props.calendarRef?.current)
            return;
        let calendarApi = props.calendarRef.current.getApi();
        calendarApi.today();
        dispatch(setNavbar({ activeStartDate: calendarApi?.currentDataManager?.getCurrentData().viewApi.currentStart }));
    };
    const closeSettings = () => {
        dispatch(openModal(undefined));
    };
    /**
     * When user has changed calendar timezone
     * @param event event
     * @param data dropdown data
     */
    const onChangeCalendarTimeZone = async (event, data) => {
        const timeZone = data?.value;
        if (!timeZone)
            return;
        dispatch(setNavbar({ calendarTimeZone: timeZone }));
        //invalidateEvents();
        if (props.calendarRef.current) {
            let calendarApi = props.calendarRef.current.getApi();
            calendarApi.setOption("timeZone", timeZone);
        }
        setSearchQuery("");
        /* const evt = new Event('change', { bubbles: true });
            const dropdownTz = document.getElementById("dropdown-tz")
            dropdownTz.dispatchEvent(evt)*/
        //return timeZone;
    };
    /**
     * Get translation of current display mode
     */
    const getTranslatedCurrentDisplayMode = () => {
        return t(DisplayMode[displayMode]);
    };
    /**
     * When user has changed calendar display mode
     * @param event event
     * @param data menu data
     */
    const onChangeDisplayMode = async (event, data) => {
        if (data?.index === undefined)
            return;
        const displayMode = displayModesDropdownItems[data.index]?.value ?? DisplayMode.Month;
        if (props.calendarRef.current) {
            //let calendarApi = props.calendarRef.current.getApi();
            dispatch(setNavbar({ displayMode }));
        }
    };
    const onChangeCalendarView = async (event, data) => {
        const displayMode = displayModesDropdownItems[data.value.key]?.value ?? DisplayMode.Month;
        if (props.calendarRef.current) {
            //let calendarApi = props.calendarRef.current.getApi();
            dispatch(setNavbar({ displayMode }));
        }
    };
    /**
     * When user clicked on action from more options menu
     * @param event event
     * @param data menu data
     */
    const onClickMoreOptionsAction = async (event, data) => {
        if (data?.index === undefined)
            return;
        const action = moreOptionsDropdownItems[data.index]?.value;
        switch (action) {
            case MoreOptionsAction.Print:
                if (!isPremium)
                    dispatch(openPremiumDialog(appInsightInstance));
                else
                    printCalendar();
                break;
            case MoreOptionsAction.Settings:
                //gerer les dialog de maniere global
                dispatch(openModal(Modal.Settings));
                break;
            case MoreOptionsAction.Permissions:
                //rediriger vers les permissions.
                if (calendar?.id)
                    navigation("/permissions/" + calendar?.id);
                break;
            case MoreOptionsAction.Tags:
                //rediriger vers les tags.
                if (calendar?.id)
                    navigation("/tags/" + calendar?.id);
                //props.history.push("/tags/" + this.context.calendar.data.id);
                break;
            case MoreOptionsAction.Sync:
                //rediriger vers la synchronisation.
                if (calendar?.id)
                    navigation("/sync/" + calendar?.id);
                break;
            case MoreOptionsAction.Rooms:
                dispatch(openModal(Modal.Rooms));
                break;
            case MoreOptionsAction.MeetingScheduler:
                //rediriger vers les paramètre du meeting scheduler.
                if (calendar?.id)
                    navigation("/meetingscheduler/" + calendar?.id);
                break;
            case MoreOptionsAction.Notifications:
                dispatch(openModal(Modal.NOTIFICATIONS));
                break;
            case MoreOptionsAction.Upgrade:
                dispatch(openModal(Modal.UPGRADE));
                break;
            default:
                break;
        }
    };
    /**
     * Print current calendar view
     */
    const printCalendar = async () => {
        // Get a picture of current calendar content
        const imageToprint = await convertHtmlElementToPng("#fullcalendar", themeClass === ThemeClass.Default);
        setState((prev) => ({ ...prev, imageToPrint: imageToprint }));
        // @ts-ignore
        // Le timeout permet de temporiser la création de "printContentIframe" sans celui-ci la première fois que l'on clique sur print il ne se passe rien car iframe vaut undefined
        setTimeout(() => {
            const iframe = window.frames["printContentIframe"];
            if (!!imageToprint && !!iframe) {
                iframe.focus();
                iframe.print();
            }
        }, 1000);
    };
    /**
     * On render navbar html element
     * @param element html element
     */
    const onRenderNavbarHtmlElement = (element) => {
        if (!element)
            return;
        props.onHeightChange(element.offsetHeight);
    };
    const changeSearchQuery = (_, data) => {
        setSearchQuery(data.searchQuery);
    };
    const changeTagsQuery = (_, data) => {
        setTagsQuery(data.searchQuery);
    };
    return {
        state,
        moreOptionsDropdownItems,
        displayModesDropdownItems,
        isDropdownSharedCalendarsOpen,
        setIsDropdownSharedCalendarsOpen,
        onDropdownTagsToggle,
        isDropdownTagsOpen,
        setIsDropdownTagsOpen,
        showPreviousEvents,
        showNextEvents,
        isTodayButtonDisabled,
        showTodayEvents,
        onFilterEventsByTag,
        onChangeCalendarTimeZone,
        getTranslatedCurrentDisplayMode,
        onChangeDisplayMode,
        onClickMoreOptionsAction,
        onRenderNavbarHtmlElement,
        setState,
        closeSettings,
        onDropdownSharedCalendarsToggle,
        handleSelectedCalendars,
        searchQuery,
        changeSearchQuery,
        queryNews,
        tagsQuery,
        changeTagsQuery,
        filteredTags,
        searchTagsQuery,
        setSearchTagsQuery,
        onChangeCalendarView
    };
};
