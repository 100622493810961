import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Dialog, ExclamationTriangleIcon, Flex, Text } from "@fluentui/react-northstar";
import { translations } from "../../../translations";
import { ThemeClass, useMsTeamsSelector, useTranslate } from "front";
import { useEventConflictLogic } from "./EventConflictDialog.logic";
export const EventConflictDialog = (props) => {
    const { isOnMobile, themeClass } = useMsTeamsSelector("isOnMobile", "themeClass");
    const conflictLogic = useEventConflictLogic(props);
    const t = useTranslate(translations);
    const renderDialog = () => {
        return (_jsxs(Flex, { fill: true, column: true, gap: "gap.medium", wrap: true, styles: {
                marginTop: "10px",
                overflowY: "scroll",
                overflowX: "hidden",
            }, children: [_jsx(Flex, { column: true, children: _jsx(Text, { content: t("ConflictBookingTagLine") }) }), _jsx("div", { dangerouslySetInnerHTML: { __html: props.conflictDatesString }, style: { border: "1px solid black", borderRadius: "5px", padding: "4px", minHeight: "135px", maxHeight: "135px", backgroundColor: themeClass === ThemeClass.Default ? "#FFF6E9" : "rgba(0, 0, 0, 0.2)", overflowY: "scroll" } })] }));
    };
    return (_jsx(Dialog, { styles: { width: isOnMobile ? "95vw" : "500px" }, defaultOpen: true, closeOnOutsideClick: true, header: _jsxs(Flex, { gap: "gap.small", vAlign: "center", children: [_jsx(ExclamationTriangleIcon, { size: "large", circular: true, outline: true, styles: { color: '#C57F1A', backgroundColor: '#FFF6E9' } }), _jsx(Text, { content: t("ConflictBookingTitle") })] }), confirmButton: t("Save"), content: renderDialog(), cancelButton: t("AdjustBooking"), onCancel: conflictLogic.closeDialog, onConfirm: () => props.onSaveEvent(true), "data-testid": "event-view-conflict-modal" }));
};
