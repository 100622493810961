import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { AcceptIcon, AddIcon, Button, ChevronStartIcon, CloseIcon, Dialog, EditIcon, Flex, FlexItem, Header, Input, SearchIcon, Segment, Table, TagIcon, Text, TrashCanIcon } from "@fluentui/react-northstar";
import { useLogic } from "./Tags.logic";
import "./Tags.styles.scss";
import { useMsTeamsSelector, useTranslate } from "front";
import { translations } from "../../translations";
export const Tags = () => {
    const t = useTranslate(translations);
    const logic = useLogic();
    const { isOnMobile } = useMsTeamsSelector("isOnMobile");
    /// Render the empty table row
    const renderEmptyTableRows = () => {
        return (_jsxs(Table.Row, { compact: true, children: [_jsx(Table.Cell, { content: t("Tag:NoEntries") }), _jsx(Table.Cell, { content: "" })] }));
    };
    /// Render the no result table row
    const renderNoResultTableRows = () => {
        return (_jsxs(Table.Row, { compact: true, children: [_jsx(Table.Cell, { content: t("Tag:NoResult", { Search: logic.state.searchValue }) }), _jsx(Table.Cell, { content: "" })] }));
    };
    const renderAllTableRows = () => {
        if (logic.state.listItems?.length == 0) {
            return renderEmptyTableRows();
        }
        const items = logic.state.listItems?.filter((tag) => tag?.toLowerCase().includes(logic.state.searchValue?.toLowerCase()));
        if (items.length == 0 && logic.state.searchValue != "") {
            return renderNoResultTableRows();
        }
        return items.map((tag, index) => {
            let tagString = tag.toString();
            return (_jsxs(Table.Row, { compact: true, children: [_jsx(Table.Cell, { className: "actionTag", styles: {
                            overflow: "hidden",
                            display: "flex"
                        }, content: logic.state.selectedTag?.index == index ?
                            _jsx(Input, { className: "actionTag", icon: _jsx(EditIcon, {}), iconPosition: "start", fluid: true, value: logic.state.selectedTag?.value, onChange: logic.editOnChange })
                            :
                                _jsx(Flex, { fill: true, styles: { overflow: "hidden" }, children: _jsx(Text, { content: tag, truncated: true }) }) }), _jsx(Table.Cell, { className: "actionTag", content: _jsx(Flex, { className: "pull-right", styles: { alignItems: "center" }, space: "between", gap: "gap.medium", children: logic.state.selectedTag?.index == index ?
                                _jsxs(_Fragment, { children: [logic.state.tagsUpdateError &&
                                            _jsx(Text, { className: " posError", style: { position: "initial", width: "100%", maxWidth: "100%" }, content: logic.state.tagsUpdateError }), _jsxs(_Fragment, { children: [_jsx(Button, { icon: _jsx(AcceptIcon, {}), iconOnly: true, text: true, size: "small", title: tagString, onClick: () => logic.handleEditItem(tagString), disabled: logic.state.readonly || logic.state.tagsUpdateError !== undefined }), _jsx(Button, { icon: _jsx(CloseIcon, {}), iconOnly: true, text: true, size: "small", title: tagString, onClick: () => logic.cancelUpdate(), disabled: logic.state.readonly })] })] })
                                :
                                    _jsxs(_Fragment, { children: [_jsx(Button, { icon: _jsx(EditIcon, {}), iconOnly: true, text: true, size: "small", title: tagString, onClick: () => logic.activeEditItem(index, tagString), disabled: logic.state.readonly || !!logic.state.selectedTag }), _jsx(Button, { icon: _jsx(TrashCanIcon, {}), iconOnly: true, text: true, size: "small", title: tagString, onClick: () => logic.openDeleteDialog(tagString), disabled: logic.state.readonly || !!logic.state.selectedTag })] }) }) })] }, "row-" + index));
        });
    };
    /**
  * Render delete dialog
  */
    const renderDeleteDialog = () => {
        if (!logic.state.tagToDelete)
            return null;
        return (_jsx(Dialog, { closeOnOutsideClick: false, header: t("Tag:DeleteTitle"), content: t("Tag:DeleteMessageFirstPart") + ` ${logic.state.tagToDelete} ` + t("Tag:DeleteMessageSecondPart"), open: !!logic.state.tagToDelete, confirmButton: {
                content: t("Delete"),
                loading: logic.mutateCalendarIsLoading,
                disabled: logic.mutateCalendarIsLoading,
            }, cancelButton: {
                content: t("Cancel"),
                disabled: logic.mutateCalendarIsLoading,
            }, onCancel: logic.closeDeleteDialog, onConfirm: logic.handleDeleteItem }));
    };
    return (_jsxs(_Fragment, { children: [_jsxs(Flex, { column: true, fill: true, styles: {
                    display: "flex",
                }, "data-testid": "tags-page", children: [_jsxs(Flex, { children: [_jsx(Flex, { styles: {
                                    padding: isOnMobile ? "15px" : "10px"
                                }, children: _jsx(Button, { icon: _jsx(ChevronStartIcon, {}), iconOnly: true, onClick: logic.returnToCalendar }) }), _jsx(Header, { as: "h3", content: t("Tag:Title") })] }), _jsx(Flex, { column: true, fill: true, styles: { margin: "0px", overflowY: "scroll" }, gap: "gap.medium", children: _jsx(Segment, { styles: {
                                margin: isOnMobile ? "0px" : "10px",
                                borderRadius: "5px",
                            }, children: _jsxs(Flex, { column: true, styles: { width: "100%" }, gap: "gap.large", children: [_jsx(Flex, { gap: "gap.medium", children: _jsx(FlexItem, { grow: true, children: _jsx(Input, { icon: _jsx(SearchIcon, {}), placeholder: t("Tag:Search"), iconPosition: "start", fluid: true, value: logic.state.searchValue, onChange: logic.searchOnChange }) }) }), _jsxs(Flex, { gap: "gap.medium", children: [_jsx(FlexItem, { grow: true, children: _jsxs("div", { children: [_jsx(Input, { icon: _jsx(TagIcon, {}), placeholder: t("Tag:Create"), iconPosition: "start", fluid: true, value: logic.state.newTag, onChange: logic.addTagOnChange }), logic.state.tagsError &&
                                                            _jsx("div", { children: _jsx(Text, { className: "errorTag", content: logic.state.tagsError }) })] }) }), _jsx(FlexItem, { children: _jsx(Button, { primary: true, content: t("Tag:CreateButton"), iconPosition: "before", icon: _jsx(AddIcon, {}), onClick: logic.handleAddItem, style: { width: "350px" }, disabled: logic.state.readonly || logic.state.newTag.trim() == "" || !!logic.state.tagsError || logic.state.newTag.length > 50 }) })] }), _jsx(Flex, { column: true, styles: { overflow: "scroll" }, gap: "gap.medium", children: _jsxs(Table, { "aria-label": "table", id: "tagTable", children: [_jsxs(Table.Row, { header: true, id: "headerTable", children: [_jsx(Table.Cell, { content: t("Tag:TableName"), style: { width: '75%' } }), _jsx(Table.Cell, { content: "", style: { width: '100px' } })] }), renderAllTableRows()] }) })] }) }) })] }), renderDeleteDialog()] }));
};
